@import '../../config/styles/variables';

.ChatMenu {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px 0px 0px 10px;
    position: absolute;
    padding: 17px;
    top: 28px;
    right: 0;
    width: 152px;
    z-index: 200;

    ul {
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 30px;
        li {
            display: block;
            font-size: 16px;
            line-height: 19px;
            font-family: "kap-firma-md";
        }
    }
}