@import '../../../config/styles/variables';

@media only screen and (max-width: 990px) {
    .HomeScreen {
        padding-bottom: 100px;

        &__desktopHeader {
            display: none;
        }

        &__banner__sticky {
            position: sticky;
            top: 0;
            background-color: #FFFFFF;
            box-shadow: 0px -10px 20px #707070;
            padding-bottom: 9px;
            z-index: 900;
        }

        .products {
            display: flex;
            flex-direction: row;
            align-items: center;
            overflow-x: auto;
            margin: 0 2vw;

        }

        &__giveaway {
            .banner {
                display: flex;
                align-items: center;
                gap: 5px;
                padding: 0 6vw;

                span {
                    color: #06335B;
                    font-size: 15px;
                    line-height: 19px;
                    font-family: 'kap-firma-md';
                    font-weight: 700;
                    cursor: pointer;
                }
            }

            .heading {
                font-family: 'kap-firma-md';
                font-style: normal;
                font-weight: 700;
                font-size: 17px;
                line-height: 26px;
                color: #06335B;
                flex: 1;
            }

        }

        &__swapmoney {
            .banner {
                background-color: #11F94F;
                color: #FFFFFF;
                padding: 24px 3vw;

                .heading {
                    display: flex;
                    justify-content: space-between;
                    font-family: 'kap-firma-md';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 23px;

                    span {
                        font-size: 13px;
                        line-height: 16px;
                        font-family: 'kap-firma-md';
                        font-weight: 500;
                        cursor: pointer;
                    }
                }
            }
        }

        &__day {
            .banner {

                color: #2D2D2D;
                padding: 7px 6vw;
                display: flex;
                align-items: center;
                gap: 5px;

                .heading {
                    display: flex;
                    align-items: center;
                    font-family: 'kap-firma-md';
                    font-style: normal;
                    font-size: 17px;
                    line-height: 23px;
                    font-weight: 700;
                    flex: 1;
                }

                span {
                    color: #06335B;
                    font-size: 15px;
                    line-height: 19px;
                    font-family: 'kap-firma-md';
                    font-weight: 700;
                    cursor: pointer;
                }
            }
        }

        &__year {
            .banner {

                padding: 7px 6vw;
                display: flex;
                align-items: center;
                gap: 5px;

                .heading {
                    color: #2D2D2D;
                    display: flex;
                    align-items: center;
                    font-family: 'kap-firma-md';
                    font-style: normal;
                    font-size: 15px;
                    line-height: 23px;
                    font-weight: 700;
                    flex: 1;

                    span {
                        margin-left: 10px;
                    }
                }

                span {
                    color: #06335B;
                    font-size: 15px;
                    line-height: 19px;
                    font-family: 'kap-firma-md';
                    font-weight: 700;
                    cursor: pointer;
                }
            }
        }
    }
}

@media screen and (min-width:1000px) {
    .HomeScreen {
        &__banner__sticky {
            display: none;
        }

        &__container {
            padding: 80px 0 0;
        }

        .HomeScreen__top {
            display: flex;
            padding: 0px 3vw 0;

            // div{
            //     border-radius: 14.5px;
            //     height: 336px;
            //     background-color: #fff;
            // }
            &__ads,
            &__categories {
                border-radius: 14.5px;
                height: 336px;
                margin: 18px 0;

                @media screen and(min-width:1200px) {
                    height: 400px;
                }
            }

            &__categories {
                width: 70vw;
                background-color: #fff;
            }

            &__ads {
                width: 45vw;
                display: flex;
                flex-direction: column;
                gap: 10px;

                div {
                    background-color: #fff;
                    flex: 50%;
                    border-radius: 14.5px;
                }
            }

            .Banner {
                margin: 0 18px;

            }

        }

        // &__giveaway,&__day, &__year {
        //     background-color: #fff;
        //     padding: 14px 3vw;
        //     border-radius: 20px;
        //     margin-bottom: 20px;
        // }
        &__giveaway,&__day, &__year {
            background-color: #fff;
            padding: 18px 6vw;
            border-radius: 20px;
            margin-bottom: 20px;
            .banner {
                display: flex;
                align-items: center;
                gap: 5px;
                padding-bottom: 18px;
                // padding: 0 6vw;
                .see{
                    // width: 7.4px;
                    height: 15.8px;
                }
                .offer{
                    width: 23px;
                }

                span {
                    color: #06335B;
                    font-size: 20px;
                    line-height: 25px;
                    font-family: 'kap-firma-md';
                    font-weight: 700;
                    cursor: pointer;
                }
            }

            .heading {
                font-family: 'kap-firma-md';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 25px;
                color: #06335B;
                flex: 1;
                display: flex;
                // align-items: center;
                gap: 10px;
            }
            .products {
                display: flex;
                flex-direction: row;
                align-items: center;
                overflow-x: auto;
                // margin: 0 2vw;
                gap: 28px;
                .Product{
                    margin: 18px 0;
                }
            }
            

        }
        &__year{
            padding: 0;
            .banner{
                background: #06335B ;
                padding: 18px 6vw;
                img{
                    
                    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(359deg) brightness(107%) contrast(101%);
                }
                span, .heading {
                    color: #fff;
                    font-size: 20px;
                    line-height: 25px;
                    font-family: 'kap-firma-md';
                    font-weight: 700;
                    cursor: pointer;
                }
            }
            .products{
                padding: 18px 6vw;
            }
        }
    }
}