.floatingMsg{
    position: fixed;
    right: 3%;
    top: 40%;
    width: 90px;
    // height: 98px;

    @media screen and (max-width:1023px) {
        display: none;
    }
    @media screen and (max-width:1350px) {
        right: 20px;
    }
}