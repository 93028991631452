@import '../../config/styles/variables';

@media only screen and (max-width: 900px) {
    .Login__form {
        margin: 130px 9vw;

        &__heading {
            color: $primary_colour;
            font-family: "kap-firma-md";
            font-size: 22px;
            text-align: center;
            margin: 27px 0;
        }

        ;

        &__subtitle,
        &__label {
            font-size: 12px;
            color: $primary_colour;
            font-family: "kap-firma-md";
            text-align: center;
            margin-bottom: 47px;

            a {
                color: $link_colour;
                font-family: "kap-firma-md";
                text-decoration: none;
            }
        }

        ;

        &__label {
            margin: 15px 0 7px 0;
            text-align: left;
        }

        ;

        &__input {
            width: 100%;
            height: 40px;
            border: 2px solid #707070;
            border-radius: 8px;
            display: flex;
            align-items: center;
            border: 0.7px solid #707070;
            input {
                width: 100%;
                height: 100%;
                border-radius: 8px;
                border: none;
                font-family: "kap-firma-md";
                font-size: 14px;
                padding: 5px;
                flex: 1;
                outline: none;
                &:focus{
                    border: none;
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                    outline: none;
                }
            }

            ;

            img {
                width: 20px;
                height: 18px;
                margin: 0 10px;
            }

            ;
        }

        ;

        &__FP {
            text-align: right;
            color: $link_colour;
            font-size: 12px;
            padding: 7px 0 0 0;
            a{
                font-family: "kap-firma-md";
                text-decoration: none;
                color: $link_colour;
            };
            
            
        }

        ;

        &__submit {
            height: 42px;
            background-color: $btn_colour;
            width: 100%;
            color: $bg_colour;
            border-radius: 12px;
            font-family: "kap-firma";
            font-size: 16px;
            border: none;
            margin: 30px auto;
            display: block;
            position: static;
        }

        ;

        &__break {
            display: flex;
            align-items: center;

            hr {
                width: 100%;
                color: #707070;
            }

            ;

            p {
                color: #707070;
                font-size: 12px;
                line-height: 17.6px;
                font-family: "kap-firma-md";
                display: block;
                margin: 15px;
                width: 100%;
                text-align: center;
            }

            ;
        }

        ;

        &__SM {
            text-align: center;

            img {
                margin: 12px;
                width: 27px
            }
        }

    }

    ;
}
@keyframes spinner {
    0% {
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
      transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
  }
  .spin::before {
    animation: 1.5s linear infinite spinner;
    animation-play-state: inherit;
    border: solid 2px #ffffff;
    border-bottom-color: $link_colour;
    border-radius: 50%;
    content: "";
    height: 20px;
    width: 20px;
    position: absolute;
    transform: translate3d(-50%, -50%, 0);
    will-change: transform;
    z-index: 1;
  }