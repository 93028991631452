@import '../../config/styles/variables';

@media only screen and (max-width: 999px) {
    .Banner{
        position: relative;
        box-shadow: 0px -10px 20px #707070;
        margin-bottom: 3vh;
        &__image{
            img{
            height: 208px;
            // background: #C4C4C4;
            border-radius: 10px;
            margin: 18px 3vw;
            width: 93%;
            
        }
        }
        &__buttons{
            display: flex;
            justify-content: center;
            flex-direction: row;
            &__location, &__sort{
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: center;               
                background-color: transparent;
                width: 167px;
                height: 43px;
                border: 0.5px solid #8C8C8C;
                border-radius: 3px;         
                font-family: 'kap-firma-lt';
                font-style: normal;
                font-weight: 1000;
                font-size: 12px;
                line-height: 15px;
                color: #000000;
                margin: 0 4vw 24px;
            }
            
        }
    }
}
@media screen and (min-width:1000px) {
    .Banner{
        &__buttons{
            display: none;
        }
    }
}