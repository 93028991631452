@import '../../../config/styles/variables';

@media only screen and (max-width: 900px) {
    .Interest {
        padding-bottom: 100px;
        &__body {
            &__heading {
                display: flex;
                border-bottom: 1px solid rgba(0, 0, 0, 0.7);
                ;
                align-items: center;
                justify-content: space-between;
                padding: 9px 0;
                margin: 14px 7vw;

                p {
                    font-family: "Kap-firma-rg";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 19px;
                    color: #000;
                    opacity: 0.9;
                }
            }

            &__items {
                .empty {
                    font-family: "kap-firma-bd";
                    font-size: 28px;
                    line-height: 33px;
                    text-align: center;
                    color: #0D0D0D;
                    opacity: 0.8;
                    margin: 50% auto 0;
                    width: 280px;
                }
            }
        }
    }
}