@import "../../config/styles/variables";

.Review{
    position: relative;
    &__author{
        display: flex;
        align-items: center;
        &__img{
            background: #C4C4C4;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            margin-right: 5px;
        }
        &__name{
            font-family: "kap-firma-lt";
            font-style: normal;
            font-weight: 900;
            font-size: 10px;
            line-height: 13px;
            color: #232323;

        }
    }
    &__message{
        justify-content: center;
        font-family: "kap-firma-lt";
        font-style: normal;
        font-weight: 1000;
        font-size: 10px;
        line-height: 13px;  
        margin: 6px 0;      
    }
    &__stars{
        position: absolute;
        bottom: -4px;
        right: 0;
        *{
            margin-right: 2px;
            width: 8px;
        }
    }
}