@import "../../config/styles/variables";

.Contact {
    a{
        text-decoration: none;
    }
    &__body {
        padding: 120px 30px 0px;

        &__top {
            // margin-top: 30px;
            margin-bottom: 83px;
            text-align: center;

            h3 {
                font-family: 'kap-firma-md';
                font-style: normal;
                font-weight: 700;
                font-size: 55.7164px;
                line-height: 71px;
            }

            p {
                font-family: 'kap-firma-rg';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 23px;
                padding-top: 16px;
                width: 388px;
                margin: auto;
            }
        }

        &__mid {
            display: flex;
            padding: 67px 45px;
            align-items: center;
            justify-content: space-between;
            gap: 111px;
            width: 100%;
            background: #EDF0F2;
            border-radius: 10px;

            &__item {
                width: 25%;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 7px;

                .topText {
                    font-family: 'kap-firma-rg';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 28px;
                    text-align: center;
                    color: #242424;
                    margin-top: 7px;
                }

                .btmText {
                    font-family: 'kap-firma-rg';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 28px;
                    text-align: center;
                    color: #242424;
                }
               
            }
        }

        &__bottom {
            // width: 80vw;
            margin: auto;
            padding: 51px 77px;

            .main {
                display: flex;
                // justify-content: center;
                align-items: center;
                // gap: 5vw;
                margin-bottom: 85px;
                &__img{
                    margin-right: 64px;
                    width: 148px;
                }

                .topText {
                    font-family: 'kap-firma-rg';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 38px;
                }

                .midText {
                    font-family: 'kap-firma-rg';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 30px;
                    width: 45vw;
                    margin-top: 9px;
                    margin-bottom: 16px;
                }

                .linkText {
                    width: 229.4px;
                    height: 27.27px;
                    font-family: 'kap-firma-rg';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 25px;
                    color: #06335B;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                }
            }
            .join {
                padding: 89px 0px;
                background: #06335B;
                border-radius: 38px;
                width: 100%;
                margin: 13px 0 58px 0;
               
                // display: none;

                .top {
                    font-family: 'kap-firma-rg';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 38px;
                    text-align: center;
                    color: #FFFFFF;
                    margin-bottom: 21px;
                    padding-left: 23px;
                    padding-right: 23px;

                }

                .bottom {
                    font-family: 'kap-firma-rg';
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 25px;
                    text-align: center;
                    color: #FFFFFF;
                    padding-left: 23px;
                    padding-right: 23px;
                    margin-bottom: 41px;

                    
                }

                button {
                    position: static;
                    width: 235.75px;
                    height: 44.94px;
                    background: #FFFFFF;
                    border: 0px;
                    border-radius: 10px;
                    margin: auto;
                    display: block;
                    color: #06335B;
                    font-family: 'kap-firma-rg';
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;


                }
            }
        }
    }
    // @media screen and (min-width:2500px) {
    //     &__body {
    //         margin: 15vw 5vw 0;
    //     }
    // }
    @media screen and(min-width:1700px) {
        .btmText {
            width: 300px;
        }
        .midText{
            width: 30vw !important;
        }
    }
    // @media screen and (min-width:1440px) {
    //     &__body {
    //         margin: 13vh 5vw 0;
    //     }
    // }

    @media screen and (max-width:999px) {
        &__body {
            padding: 12vh 5vw 0;

            &__top {
                margin-top: 30px;
                margin-bottom: 50px;
                text-align: start;

                h3 {
                    font-size: 25px;
                    line-height: 31px;
                }

                p {
                    font-size: 14px;
                    line-height: 18px;
                    width: 268px;
                    margin: 0;
                }
            }

            &__mid {
                display: flex;
                flex-direction: column;
                padding: 0px 0px;
                align-items: center;
                // gap: 20px;
                // height: 253px;
                width: 100%;
                background: #fff;
                border-radius: 10px;
                justify-content: center;
                *{
                    margin-bottom: 20px;
                }

                .mob-hide{
                    display: none;
                }

                &__item {
                    background: rgba(6, 51, 91, 0.06);
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 55px 53px;
                    border-radius: 50px;
                    justify-content: center;
                    // gap: 10px;

                    .topText {
                        font-family: 'kap-firma-md';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 22px;
                        line-height: 28px;
                        text-align: center;
                        color: #242424;
                        margin-top: 7px;
                    }

                    .btmText {
                        font-family: 'kap-firma-md';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 20px;
                        text-align: center;
                        color: #242424;
                    }
                }
            }

            &__bottom {
                margin: 0;
                padding: 51px 0vw 0px;

                .main {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    // gap: 10px;
                    width: inherit;
                    // padding-left: 5vw;
                    // padding-right: 5vw;
                    margin-bottom: 40px;
                    *{
                        margin-bottom: 10px;
                    }
                    img {
                        width: 62px;
                    }

                    .topText {
                        font-size: 20px;
                        line-height: 25px;
                    }

                    .midText {
                        font-size: 14px;
                        line-height: 18px;
                        width: 80vw;

                        margin-top: 4px;
                        margin-bottom: 10px;
                    }

                    .linkText {
                        font-size: 14px;
                        line-height: 25px;
                        color: #06335B;
                        display: flex;
                        align-items: center;
                        gap: 5px;

                        img {
                            width: 14px;
                            margin-bottom: 2px;
                        }
                    }
                }

                .join {
                    padding: 57px 0px;
                    background: #06335B;
                    border-radius: 38px;
                    width: 100%;
                    margin-top: 40px;
                    display: block;

                    .top {
                        font-family: 'kap-firma-md';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 22px;
                        line-height: 28px;
                        text-align: center;
                        color: #FFFFFF;
                        margin-bottom: 21px;
                        padding-left: 23px;
                        padding-right: 23px;

                    }

                    .bottom {
                        font-family: 'kap-firma-rg';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        text-align: center;
                        color: #FFFFFF;
                        padding-left: 23px;
                        padding-right: 23px;
                        margin-bottom: 41px;
                    }

                    button {
                        position: static;
                        width: 235.75px;
                        height: 44.94px;
                        background: #FFFFFF;
                        border-radius: 10px;
                        margin: auto;
                        display: block;
                        color: #06335B;
                        font-family: 'kap-firma-rg';
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 20px;

                    }
                }
            }
        }
        // .tweetBig{width: 33px !important;}
    }
    // .tweetBig{width: 36.5px ;}
    // @media screen and (max-width:480px) {
    //     .tweetBig{width: 33px !important;}
    //   }
}