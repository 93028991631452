.Select{
    display: flex;
    flex-direction: column;
    flex: 1 !important;
    position: relative;
    
    label {
        margin-bottom: 8px;
        text-transform: capitalize;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 19px;
        font-family: "kap-firma-rg";
    }
    div {
        background: #F5F5F5;
        border-radius: 10px;
        height: 62px;
        max-width: 168px;
        display: flex;
        align-items: center;
        padding: 11px;
        p{
            font-weight: 400;
            font-size: 15px;
            line-height: 19px;
            font-family: "kap-firma-rg";
        }
    }
    &__options{
        background-color: #FFFFFF !important;
        box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        position: absolute;
        top: 70px;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start !important;
        height: fit-content !important;
        p{
            font-weight: 400;
            font-size: 15px;
            line-height: 19px;
            font-family: "kap-firma-rg";
            color: #000000 !important;
            background-color: transparent;
            padding-bottom: 4px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.25);
            width: 100%;
            margin-top: 20px;
        }
        margin-bottom: 200px !important;
    }
}