@import '../../config/styles/variables';

@media only screen and (max-width: 999px) {
    .Tabx{
        .active{
            filter: invert(69%) sepia(48%) saturate(431%) hue-rotate(102deg) brightness(0%) contrast(86%);
        }
        .react-tabs{
            height: 100%;
            &__tab-list{
                position: fixed;
                padding:3.5vh 7vw 3vh;
                height: 55px;
                bottom: 20px;
                width: 70%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #FFFFFF;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                border-radius: 100px;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                z-index: 900;
            };
            &__tab{
                text-decoration: none;
                display: block;
            }
            .hide{
                display: none;
            }
        }
    }
}
@media screen and (min-width:1000px) {
    .Tabx{
        .react-tabs{
            &__tab-list{
                display: none;
            }
        }
    }
}