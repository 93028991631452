@import '../../config/styles/variables';

.WebFooter {
    background-color: #ffffff;

    &__main {
        display: -webkit-box;
        /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box;
        /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox;
        /* TWEENER - IE 10 */
        display: -webkit-flex;
        /* NEW - Chrome */
        display: flex;
        flex-direction: column;
        // column-gap: 28px;
        // gap: 26px;
        // *{
        //     padding-bottom: 26px;
        // }
            .copyright *{
                padding: 0 !important;
            }
        &__copyright {
            display: none;
        }

        &__socials {
            display: flex;
            justify-content: space-between;
            width: 125px;
        }
        &__socials, &__logo{
            padding-bottom: 26px;
        }
    }

    p,
    a {
        font-family: 'kap-firma-rg';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #4B4B4B;
        text-decoration: none;
    }

    @media screen and (max-width: 999px) {
        padding: 32px 5vw 75px;

        &__main {
            // display: flex;
            // flex-direction: column;
            // gap: 26px;

            // &__socials {
            //     display: flex;
            //     justify-content: space-between;
            //     width: 125px;
            // }

            &__info {
                display: -webkit-box;
                /* OLD - iOS 6-, Safari 3.1-6 */
                display: -moz-box;
                /* OLD - Firefox 19- (buggy but mostly works) */
                display: -ms-flexbox;
                /* TWEENER - IE 10 */
                display: -webkit-flex;
                /* NEW - Chrome */
                display: flex;
                justify-content: space-between;
                align-items: center;

                .pro {
                    display: none;
                }

                .right {
                    text-align: right;
                }

                .third {
                    display: none;
                }

                &__section {
                    display: -webkit-box;
                    /* OLD - iOS 6-, Safari 3.1-6 */
                    display: -moz-box;
                    /* OLD - Firefox 19- (buggy but mostly works) */
                    display: -ms-flexbox;
                    /* TWEENER - IE 10 */
                    display: -webkit-flex;
                    /* NEW - Chrome */
                    display: flex;
                    flex-direction: column;
                    // column-gap: 28px;
                    // gap: 28px;
                    *{
                        padding-bottom: 28px;
                    }

                    &__header {
                        width: 100px;
                        font-family: 'kap-firma-md';
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 20px;
                        color: #06335B;
                    }
                }

            }

            .mobileBottom {
                align-items: unset;
                
                .low {
                    padding-top: 28px;
                }

                small {
                    font-style: normal;
                    font-family: 'kap-firma-md';
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    color: #4B4B4B;
                   
                }
            }

        }

    }

    @media screen and(min-width:1000px) {
        padding: 32px 5vw;

        &__main {
            *{
                padding-bottom: 27px;
            }
            // gap: 27px;

            .mobileBottom {
                display: none;
            }

            &__info {
                display: -webkit-box;
                /* OLD - iOS 6-, Safari 3.1-6 */
                display: -moz-box;
                /* OLD - Firefox 19- (buggy but mostly works) */
                display: -ms-flexbox;
                /* TWEENER - IE 10 */
                display: -webkit-flex;
                /* NEW - Chrome */
                display: flex;
                // align-items: center;
                justify-content: space-between;
                width: 70vw;
                padding-bottom: 49px;

                .pro-container {
                    a {
                        display: -webkit-box;
                        /* OLD - iOS 6-, Safari 3.1-6 */
                        display: -moz-box;
                        /* OLD - Firefox 19- (buggy but mostly works) */
                        display: -ms-flexbox;
                        /* TWEENER - IE 10 */
                        display: -webkit-flex;
                        /* NEW - Chrome */
                        display: flex;
                        row-gap: 6px;
                        gap: 6px;
                        //align-items: center;
                        width: fit-content;
                    }

                }

                .pro {
                    display: -webkit-box;
                    /* OLD - iOS 6-, Safari 3.1-6 */
                    display: -moz-box;
                    /* OLD - Firefox 19- (buggy but mostly works) */
                    display: -ms-flexbox;
                    /* TWEENER - IE 10 */
                    display: -webkit-flex;
                    /* NEW - Chrome */
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 63px;
                    // height: 17.26px;
                    background: #06335B;
                    border-radius: 86.3014px;
                    font-family: 'kap-firma-rg';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 6.90411px;
                    // line-height: 9px;
                    // padding: 3px 8px 3px;
                    color: #FFFFFF;
                    padding: 0 !important;
                }

                &__section {
                    display: -webkit-box;
                    /* OLD - iOS 6-, Safari 3.1-6 */
                    display: -moz-box;
                    /* OLD - Firefox 19- (buggy but mostly works) */
                    display: -ms-flexbox;
                    /* TWEENER - IE 10 */
                    display: -webkit-flex;
                    /* NEW - Chrome */
                    display: flex;
                    flex-direction: column;
                    // column-gap: 28px;
                    // gap: 28px;
                    *{
                        padding-bottom: 28px;
                    }

                    &__header {
                        width: 80px;
                        font-family: 'kap-firma-md';
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 20px;
                        color: #06335B;
                    }

                }
            }

            &__copyright {
                margin: auto;
                text-align: center;
                display: block;

                small {
                    font-style: normal;
                    font-family: 'kap-firma-md';
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    color: #4B4B4B;
                }
            }
        }
    }
}