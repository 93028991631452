@import "../../config/styles/variables";

.Profile{
    padding-bottom: 100px;
    &__hero{
        background-image: url("../../props/images/profile-bg.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 184px;
    }
    &__user{
        &__info{
            display: flex;
            align-items: center;
            margin: -30px 3vw 0;
            &__img{
                width: 99px;
                height: 99px;
                border: 6px solid white;
                border-radius: 50%;
                background-image: url("../../props/images/user.png");
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                margin-right: 5px;
            }
            &__id{
                &__name{
                    font-family: "kap-firma-rg";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #070F1C;
                    display: flex;
                    align-items: center;
                    margin-top: 12px;
                    padding: 0;
                    text-transform: capitalize;
                    img{
                        margin-left: 5px;
                    }
                }
                &__tags{
                    font-family: "kap-firma-rg";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 15px;
                    color: #222222;
                    padding: 0 7px;
                    background: #EAEAEA;
                    border-radius: 99px;
                    margin-right: 7px;
                    vertical-align: middle;
                    img{
                        vertical-align: middle;
                    }
                }
            }
            &__edit{
                font-family: "kap-firma-lt";
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                line-height: 13px;
                color: #57CC99;
                justify-self: flex-end;
                text-align: right;
                flex: 1;
                padding-right: 20px;
            }
        }
        &__rating{
            margin: 20px 3vw;
            position: relative;
            &__score{
                font-family: "kap-firma-rg";
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                span{
                    font-family: "kap-firma-rg";
                    font-weight: 400;
                }
            }
            img{
                margin: 0 4px 0 0;
            }
            &__cta{
                display: flex;
                align-items: center;
                position: absolute;
                right: 0;
                top: -8px;
                p{
                    font-family: "kap-firma-rg";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 16px;
                    text-align: right;
                    width: 59px;
                    margin-right: 10px;
                }
                div{
                    border: none;
                    width: 41px;
                    height: 41px;
                    background: #06335B;
                    box-shadow: 0px 4px 4px rgba(6, 51, 91, 0.25);
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        margin: 0;
                    }
                }
            }

        }
        &__performance{
            position: relative;
            margin: 0 3vw;
            &__heading{
                font-family: "kap-firma-rg";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                display: flex;
                align-items: center;
                margin-bottom: 12px;
                img{
                    margin-left: 5px;
                    width: 18px;
                }
            }
            &__stats{
                display: flex;
                align-items: center;
                img{
                    margin-right: 5px;
                }
                span{
                    margin-left: 5px;
                    font-family: "kap-firma-rg";
                    font-style: normal;
                    font-weight: 900;
                }
                font-family: "kap-firma-lt";
                font-style: normal;
                font-weight: 900;
                font-size: 13px;
                line-height: 16px;
                color: #111111;
                margin: 5px 0;
            }
            &__more{
                font-family: "kap-firma-lt";
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                line-height: 13px;
                color: #57CC99;
                position: absolute;
                right: 20px;
                bottom: 0;
            }
        }
        h3{
            margin: 30px 3vw 21px;
            font-family: "kap-firma-rg";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #070F1C;
        }
        &__items{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-evenly;
            width: 100%;
        }
        &__add{
            position: fixed;
            bottom: 84px;
            right: 0;
            margin-right: 3vw;
        }
    }
}