@import "../../config/styles/variables";
.Footer{
    .active{
        filter: invert(69%) sepia(48%) saturate(431%) hue-rotate(102deg) brightness(0%) contrast(86%);
    }
    
    position: fixed;
    padding:0vh 7vw;
    height: 55px;
    bottom: 20px;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;
    // border-top-left-radius: 24px;
    // border-top-right-radius: 24px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    transition: transform 300ms ease-in-out 0s, opacity 300ms ease-in-out 0s;
    z-index: 3;
    transform: translateY(-20px);
    .notification-count {
        position: absolute;
        z-index: 1;
        top: 10px;
        margin-left: 18px;
        width: 18px;
        height: 11px;
        background: #C13333;
        border-radius: 19px;
        padding: 0px 5px 0px;
        // animation: zoom 3s 3s both infinite;
        justify-content: center;
        text-align: center;
        display: flex;
        align-items: center;
        font-size: 10px;
        line-height: 13px;
        color: #fff;
        // font-weight: 600;
        font-family: "kap-firma-rg";
      }
      .msg{
        margin-left: 14px;
        top: 8;
      }
      @media screen and (min-width: 1000px) {
        display: none;
      }
}
.Fhide{
  opacity: 0;
}
