select {
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0 1em 0 2em;
    margin: 0;
    width: 100%;
    cursor: inherit;
    z-index: 1;
    &::-ms-expand {
      display: none;
    }
    outline: none;
    font-size: 15px;
    line-height: 19px;
    font-family: "kap-firma-rg";
    option{
        background: #F5F5F5;
        border-radius: 10px;
        height: 62px;
        max-width: 168px;
        display: flex;
        align-items: center;
        padding: 11px;
        overflow-y: auto;
    }
}
.Questions{
    font-size: 15px;
    line-height: 19px;
    font-family: "kap-firma-rg";
    // width: 100%;
    padding:5vw 2vw 2vh 5vw ;
    background: #F5F5F5;
    border-radius: 10px;
    margin-left:6vw;
    margin-right: 6vw;
    label{
        margin-left: 7px;
        span{
            margin-right: 3px;
        }
    }
    select{
        margin-bottom: 15px;
        padding-bottom:3vw ;
        padding-top: 3vw;
        border-bottom: 0.5px darkgray solid;
    }
}