@import '../../config/styles/variables';

@media only screen and (max-width: 900px) {
    .Location {
        position: absolute;
        z-index: 10000;
        width: 100%;
        height: 100vh;
        background-color: #FFFFFF;
        overflow-y: scroll;
        top: 0;
        left: 0;
        padding-bottom: 30px;
        font-family: "kap-firma-rg";

        sc * {
            font-family: "kap-firma-rg";
        }

        &__header {
            display: flex;
            align-items: center;
            padding: 29px 5vw 17px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            gap: 30px;
            position: sticky;
            top: 0;
            background-color: #FFFFFF;
            z-index: 2;

            h3 {
                font-family: "kap-firma-rg";
                font-style: normal;
                font-weight: 500;
                font-size: 22px;
                line-height: 28px;
            }
        }
        &__text{
            margin: 50px 5vw 0;
            h3{
                font-family: "kap-firma-rg";
                font-weight: 600;
                font-size: 22px;
                line-height: 28px;
                margin-bottom: 11px;
                p{
                    font-family: "kap-firma-rg";
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 19px;
                }
            }
        }

        &__form {
            padding: 20px 0 100px;
            overflow-y: scroll;
            position: relative;

            // &__image {
            //     border: 1px solid rgba(0, 0, 0, 0.25);
            //     border-radius: 21px;
            //     margin: 0 6vw 26px;
            //     display: flex;
            //     flex-direction: column;
            //     align-items: center;
            //     justify-content: center;
            //     position: relative;

            //     label {
            //         padding: 37px 0 60px;
            //         p {
            //             text-align: center;
            //             font-style: normal;
            //             font-family: "kap-firma-rg";
            //             font-weight: 500;
            //             font-size: 17px;
            //             line-height: 21px;
            //         }
            //     }

            //     .size {
            //         position: absolute;
            //         bottom: -28px;
            //         font-family: "kap-firma-rg";
            //         font-weight: 500;
            //         font-size: 12px;
            //         line-height: 15px;
            //         color: #000000;
            //         opacity: 0.7;
            //     }

            //     input[type="file"] {
            //         position: absolute;
            //         z-index: -1;
            //         opacity: 0;
            //     }
            // }
            &__save{
                background: #06335B;
                box-shadow: 0px 4px 4px rgba(6, 51, 91, 0.25);
                border-radius: 4px;
                border: none;
                font-family: "kap-firma-rg";
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 23px;
                color: #FFFFFF;
                width: 80px;
                height: 35px;
                margin-left: 10px;
            }
            &__location {
                display: flex;
                align-items: center;
                gap: 10px;
                margin: 50px 6vw;
               

                &__textInp {
                    display: flex;
                    flex-direction: column;
                    flex: 1;

                    label {
                        margin-bottom: 8px;
                        text-transform: capitalize;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 15px;
                        line-height: 19px;
                        font-family: "kap-firma-rg";
                    }

                    input[type="text"] {
                        border: none;
                        outline: none;
                        background: #F5F5F5;
                        border-radius: 10px;
                        height: 62px;
                        padding: 0 15px;
                        max-width: 168px;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 19px;
                        font-family: "kap-firma-rg";
                    }

                    div {
                        background: #F5F5F5;
                        border-radius: 10px;
                        height: 62px;
                        max-width: 168px;
                        display: flex;
                        align-items: center;
                        padding: 11px;
                    }
                }
            }

            &__textInp {
                margin: 50px 6vw;
                display: flex;
                flex-direction: column;

                label {
                    margin-bottom: 8px;
                    text-transform: capitalize;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 19px;
                    font-family: "kap-firma-rg";
                }

                input[type="text"] {
                    border: none;
                    outline: none;
                    background: #F5F5F5;
                    border-radius: 10px;
                    height: 62px;
                    padding: 0 15px;
                }

                div {
                    background: #F5F5F5;
                    border-radius: 10px;
                    height: 62px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    padding: 11px;
                }
            }

            &__switch {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 20px 6vw;

                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 23px;
                    font-family: "kap-firma-rg";
                }
            }
        }
    }

    // button {
    //     background: #06335B;
    //     border-radius: 5px;
    //     border: none;
    //     font-family: "kap-firma-rg";
    //     font-style: normal;
    //     font-weight: 600;
    //     font-size: 20px;
    //     line-height: 25px;
    //     color: #FFFFFF;
    //     width: 118px;
    //     height: 40px;
    //     position: absolute;
    //     right: 0;
    //     margin: 0 6vw;
    // }
    }


//     .switch {
//         position: relative;
//         display: inline-block;
//         width: 50px;
//         height: 26px;
//     }

//     /* Hide default HTML checkbox */
//     .switch input {
//         opacity: 0;
//         width: 0;
//         height: 0;
//     }

//     /* The slider */
//     .slider {
//         position: absolute;
//         cursor: pointer;
//         top: 0;
//         left: 0;
//         right: 0;
//         bottom: 0;
//         background-color: #ccc;
//         -webkit-transition: .4s;
//         transition: .4s;
//     }

//     .slider:before {
//         position: absolute;
//         content: "";
//         height: 19.64px;
//         width: 19.64px;
//         left: 4px;
//         bottom: 4px;
//         background-color: white;
//         -webkit-transition: .4s;
//         transition: .4s;
//     }

//     input:checked+.slider {
//         background-color: #06335B;
//     }

//     input:focus+.slider {
//         box-shadow: 0 0 1px #06335B;
//     }

//     input:checked+.slider:before {
//         -webkit-transform: translateX(22.5px);
//         -ms-transform: translateX(22.5px);
//         transform: translateX(22.5px);
//     }

//     /* Rounded sliders */
//     .slider.round {
//         border-radius: 20px;
//     }

//     .slider.round:before {
//         border-radius: 50%;
//     }
//     // select {
//     //     appearance: none;
//     //     background-color: transparent;
//     //     border: none;
//     //     padding: 0 1em 0 2em;
//     //     margin: 0;
//     //     width: 100%;
//     //     cursor: inherit;
//     //     z-index: 1;
//     //     &::-ms-expand {
//     //       display: none;
//     //     }
//     //     outline: none;
//     //     font-size: 15px;
//     //     line-height: 19px;
//     //     font-family: "kap-firma-rg";
//     // }
//     // .Questions{
//     //     font-size: 15px;
//     //     line-height: 19px;
//     //     font-family: "kap-firma-rg";
//     //     // width: 100%;
//     //     padding:5vw 2vw 2vh 5vw ;
//     //     background: #F5F5F5;
//     //     border-radius: 10px;
//     //     margin-left:6vw;
//     //     margin-right: 6vw;
//     //     label{
//     //         margin-left: 7px;
//     //         span{
//     //             margin-right: 3px;
//     //         }
//     //     }
//     //     select{
//     //         margin-bottom: 15px;
//     //         padding-bottom:3vw ;
//     //         padding-top: 3vw;
//     //         border-bottom: 0.5px darkgray solid;
//     //     }
//     // }
// }
