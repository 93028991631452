.coreTeam{
    hr {
            margin-top: 40px;
            z-index: -10;
            position: absolute;
            width: 85vw;
            color: #afabab;
            height: 0.4px;
        }
    
    &_member{
        margin-bottom: 5vh;
        &_img{
            position: relative;
            // justify-content: center;
            // align-items: center;
            img{
                display: block;
                margin-left: auto;
                margin-right: auto;
                //width: 50%;
            }
        }
    
        &_text{
            padding-top: 13px;
            font-style: normal;
            font-weight: 600;
            text-align: center;
            .name{
            font-size: 25px;
            line-height: 31px;
            }
            .position{
                font-size: 12px;
                line-height: 12.57px;
                color: rgb(159, 156, 156);
            }
        }
    }
}