.ProductPage {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 16px;
  padding-top: 48px;
  header {
    position: fixed;
    background-color: #fff;
    box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.071);
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    padding: 16px;
    .header {
      display: none;
      justify-content: space-between;
      img {
        display: block;
      }

      nav {
        ul {
          display: flex;

          li {
            display: flex;
            align-items: center;
            list-style: none;
            margin-right: 40px;
            font-family: "kap-firma";
            color: #4a4a4a;

            svg {
              display: block;
            }

            p {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
  &__product {
    width: 100%;

    &__info {
      &__content {
        display: flex;
        flex-direction: column;

        img {
          height: 326px;
          margin-bottom: 24px;
          object-fit: contain;
        }
        &__hero {
          display: flex;
          align-items: center;

          &__wrap {
            flex-basis: 100%;

            &__location {
              display: flex;
              align-items: center;
              margin-top: 8px;

              p {
                font-family: "kap-firma-rg";
                margin-left: 4px;
                color: #1a1a1a;
                font-size: 12px;
              }
            }
            &__mobile__review {
              display: flex;
              align-items: center;
              margin-bottom: 8px;

              p {
                font-family: "kap-firma-rg";
                font-size: 12px;
              }
            }
            h1 {
              font-size: 16px;
              font-family: "kap-firma";
              margin-bottom: 8px;
              color: #212121;
            }
          }
          &__actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            button {
              border: none;
              color: #f5f5f5;
              font-family: "kap-firma";
            }
            &__swap {
              display: block;
              padding: 8px 20px;
              border-radius: 4px;
              background-color: #06335b;
            }

            &__save {
              display: none;
              flex-shrink: 0;
              width: 48px;
              height: 48px;
              align-items: center;
              justify-content: center;
              border-radius: 100%;
              margin-left: 16px;
              box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.2);

              svg {
                width: 32px;
                height: 32px;
              }
            }
          }
        }
      }
      &__description {
        margin-top: 24px;
        padding: 16px;
        border-radius: 10px;
        border: 0.5px solid rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.19);

        h2 {
          margin-bottom: 8px;
          font-family: "kap-firma-md";
          font-size: 18px;
          color: #212121;
        }

        p {
          font-family: "kap-firma-md";
          line-height: 1.5em;
          margin-top: 8px;
          font-size: 14px;
          color: #1a1a1ac9;
        }
      }
    }
    &__items {
      position: relative;

      padding: 16px;
      background-color: #fff;
      border-radius: 20px;

      margin-top: 32px;
      max-width: 100%;
      &__list {
        overflow-x: scroll;
      }
      ul {
        display: flex;
        div {
          flex-shrink: 0;
        }
      }
    }
  }
  &__side {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 32px;
    section {
      padding: 24px;
      background-color: #fff;
      border-radius: 20px;
    }

    &__swapper {
      width: 100%;
      border: 0.5px solid rgba(0, 0, 0, 0.2);
      &__heading {
        font-family: "kap-firma-rg";
        margin-bottom: 16px;
      }
      &__profile {
        display: flex;
        align-items: center;

        justify-content: space-between;

        &__group {
          display: flex;
          align-items: center;
          justify-content: space-between;

          div {
            margin-left: 16px;
            p {
              font-size: "kap-firma-rg";
              font-size: 14px;
            }

            .heading {
              font-family: "kap-firma";
              font-size: 16px;
            }
          }
        }

        img {
          width: 56px;
          height: 56px;
          border-radius: 100%;
        }
      }
      &__score {
        padding: 40px 0;

        p {
          font-family: "kap-firma-rg";
          span {
            font-family: "kap-firma";
          }
        }
      }
      &__message {
        display: flex;
        align-items: center;
        svg {
          background-color: #06335b;
          box-shadow: 0px 4px 4px 0px rgba(6, 51, 91, 0.25);
          padding: 8px;
          border-radius: 100%;
          margin-right: 12px;
        }
        p {
          font-family: "kap-firma-rg";
          font-size: 18px;
        }
      }
    }
    &__reviews {
      width: 100%;
      border: 0.5px solid rgba(0, 0, 0, 0.2);
      margin-top: 16px;
      &__heading {
        font-family: "kap-firma-rg";
      }

      &__review {
        padding: 24px 0;
        &__rate {
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;

          p {
            font-size: 14px;
            color: #9e9e9e;
          }
        }
        &__reviewer {
          display: flex;
          align-items: center;
          img {
            width: 24px;
            height: 24px;
            border-radius: 100%;
          }

          p {
            margin-left: 8px;
            font-family: "kap-firma";
          }
        }
      }
    }
  }
}

/* Tablet */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .ProductPage {
    &__product {
      &__info {
        &__content {
          &__hero {
            &__wrap {
              p {
                margin: 8px 0;
              }
            }
          }
        }
      }
    }
    &__side {
      margin-top: 24px;
      flex-direction: row;
      &__swapper {
        flex-basis: 50%;
      }
      &__reviews {
        margin-left: 24px;
        flex-basis: 50%;
      }
    }
  }
}

/* From Tablet */
@media screen and (min-width: 768px) {
  .ProductPage {
    background-color: #f5f5f5;
    padding: 48px;
    padding-top: 128px;

    &__product {
      &__info {
        background-color: #fff;
        border: 0.5px solid rgba(0, 0, 0, 0.2);

        padding: 32px;
        border-radius: 20px;
        &__content {
          flex-direction: row;
          align-items: center;
          img {
            flex: 1;
            display: block;
            max-height: 226px;
            margin-bottom: 0;
          }

          &__hero {
            flex: 2;
            margin-left: 32px;
            flex-direction: column;
            align-items: stretch;
            &__wrap {
              &__location {
                margin-top: 8px;
                margin-bottom: 16px;

                p {
                  font-size: 14px;
                  margin-left: 4px;
                }
              }
              &__mobile__review {
                display: none;
              }
            }
            h1 {
              font-size: 30px;
              font-family: "kap-firma";
              color: #212121;
              font-weight: 500;
            }

            &__actions {
              justify-content: flex-start;
              padding: 0;
              &__swap {
                width: 50%;
                font-size: 18px;
                padding: 16px;
              }

              &__save {
                display: flex;
              }
            }
          }
        }
      }
      &__items {
        border: 0.5px solid rgba(0, 0, 0, 0.2);
      }
    }
    &__side {
      &__reviews {
        margin-top: 0;
      }
    }
  }
}

/* Desktop */
@media screen and (min-width: 1024px) {
  .ProductPage {
    flex-direction: row;
    header {
      padding: 32px;
      .header {
        display: flex;
      }
      .mobile__header {
        display: none;
      }
    }
    &__product {
      max-width: 65%;
      &__info {
        flex-basis: 70%;
      }
    }

    &__side {
      flex-basis: 35%;
      flex-direction: column;
      margin-left: 24px;
      margin-top: 0;
      &__swapper {
        width: 100%;
        margin-bottom: 24px;
      }
    }
  }
}