@import '../../config/styles/variables';

.faq {
  
  &_body {
    padding: 8vh 5vw 40px 5vw;

    * {
      font-family: 'kap-firma-rg';
      font-style: normal;
      line-height: 20.11px;
      
    }
  }

  &_Heading {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    margin-top: 25px;
    margin-bottom: 20px;
    width: 80%;
    // text-align:center;
  }

  // .accordion {
    
  // }

  .accordion-item {
    width: 100%;
    margin-top: 18px;
  }


  .accordion-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 10px;
    // height: 58px;
    align-items: center;
    padding: 1rem;
    // outline: red;
     -webkit-tap-highlight-color: transparent;
  }

  .accordion-title:hover {
    background-color: #c5c5c5;
  }

  .accordion-title,
  .accordion-content {
    font-size: 14px;
    border-radius: 10px;
    background-color: #F7F7F7;
  }

  .accordion-content {
    width: 100%;
    margin-top: 18px;
    padding: 1rem;
    height: fit-content;
    min-height: 93px;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .title {
    margin-right: 10px;
   
  }

  .pointer {
    margin-top: auto;
    margin-bottom: auto;

    p {
      font-family: 'kap-firma-rg';
      font-weight: 400;
      font-size: 25px;
      line-height: 31px;
      width: 18px;
      height: 31px;
    }
  }

  a {
    color: #67DFB4;
    text-decoration: underline;
  }
  .no-underline{
    text-decoration: none; 
  }

  .faq-item {
    width: 100%;
    * {
      padding-bottom: 12px;
    }
      
    ul,
    ol {
      // padding-left: 1rem;
      list-style-position: inside; 
    }

    ul{
      li::before{
        content: "";
        margin-right: -10px;
      }
    }
    
  }

  .bold {
    font-weight: 900;
    padding-bottom: 6px;
  }
  .spaced{
    ul{
      li{
        padding-bottom: 16px;
      }
      p{
        padding-bottom: 36px;
      }
     
    }
    p,li{
      padding-bottom: 14px;
    }
  }

  .g-info {
    background: #AFAFAF;
    width: 100%;
    height: 39px;
    color: #ffffff;
    padding: 10px 14px;
    margin-top: 39px;
    margin-bottom: 30px;
  }

  .active {

    .accordion-title {
      // color: #ffffff;
      background-color: #dedede;
    }
  }

  @media screen and (min-width: 700px) {
    &_body {
      background: #F8F8F8;
      padding: 12vh 10vw 0vh 10vw;
    }

    &_Heading {
      text-align: center;
      font-size: 35px;
      line-height: 44px; 
      width: 100%;
      margin: 14px auto 50px;
    }
    .accordion-title,
    .accordion-content {
      background: #FFF;
    }
    .accordion-title {
      padding: 0 35px 0 27px;
      height: 58px;
    }

    .accordion-content {
      // font-size: 18px;
      padding: 1rem 35px 1rem 27px;
    }

  }
  @media screen and (min-width: 1000px) {
    &_body {
      padding: 12vh 10vw 12vh 10vw;
    }
  }
  // .tweetBig{width: 36.5px ;}
  // @media screen and (max-width:600px) {
  //   .tweetBig{width:29px !important;}
  // }
}