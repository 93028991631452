@import "../../config/styles/variables";
.About{
    &_body{
        margin: 7vh 3vw 15vh 3vw;
        font-style: normal;
        color: #000000;
        *{
            font-family:'kap-firma-md';
        }
      
        .About_textHeading{
            font-weight: 500;
            font-size: 28px;
            line-height: 44px;
            margin-bottom:35px;
            text-align: center;
        }
        .About_item, #About_item{
            width: 80%;
            border-radius: 5px;
            // height: px;
            // width: 100%;
            font-weight: 500;
            font-size: 20px;
            line-height: 25px;
            border: 0;
            margin: 0px auto;
            position: static;
            background: #F7F7F7;
            text-align: center;
            margin-bottom:25px ;
            padding-top: 15px;
            padding-bottom: 15px;
            align-items: center;
        }
        .hide{
            display: none;
        }
        .About_display{
            display: block;
            width: 100%;
            margin-top: 18px;
            font-weight: 400;
            font-size: 18px;
            line-height: 23px;
            ul{
                 margin-left: 0vw;
                 padding-left: 5vw;
                 padding-bottom: 2vh;
                 li{
                     margin-top: 5px;
                 }
            }
            p{
                margin-bottom: 15px;
            }
            .head{
                font-weight: 600;
                font-size: 20px;
                line-height: 25px;
            }
        }
        .active{
            color: #ffffff;
            background-color: #06335B;
        }
        
    }
}