@import '../../config/styles/variables';

@media only screen and (max-width: 900px) {}

.Privacy {

    color: #000;

    &_Policy {
        padding: 7vw 5vw 10vh 5vw;

        * {
            font-family: 'kap-firma-rg';
            font-style: normal;
            font-size: 17px;
            line-height: 21.37px;
            font-weight: 400;
            margin: 0;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        // gap: 26px;
        div{
            padding-bottom: 26px;
        }

        a {
            font-weight: 100;
            color: #57CC99;
            text-decoration: underline;
            cursor: pointer;
            font-size: 14px;
        }
    }

    &_Header {
        font-family: 'kap-firma-md';
        margin-bottom: 28px
    }

    .Main_Header {
        // align-items: center;
        font-size: 24px;
        line-height: 27.65px;
        font-weight: 600;
        margin-bottom: 28px
    }

    .Minor_Header {
        font-size: 20px;
        line-height: 25.14px;
        // padding-bottom: 2vw;
    }

    .Sub_Header {
        font-size: 17px;
        line-height: 21.37px;
        // padding-bottom: 2vw;
    }

    .Main_Header, .Sub_Header,
    .Minor_Header,
    .List_Header {
        // padding-top: 26px;
        font-weight: 600;
        padding-bottom: 5px;
    }

    .List_Header, .Sub_Header {
        font-family: 'kap-firma-md';
    }

    .List_Header,
    .Content {
        font-size: 14px;
    }

    ul {
        padding-left: 16px;
        // list-style: inside;
    }

    li {
        padding-bottom: 26px;
        font-size: 14px;
    }

    .Cookie_Header {
        padding-top: 0px;
    }

    .indent {
        padding-top: 26px
    }

    .pb {
        padding-bottom: 13px;
    }

    @media screen and (min-width:1000px) {
        &_Policy {
            padding: 100px 10vw;
        }
        .Main_Header {
            // align-items: center;
            font-weight: 600;
            font-size: 35px;
            line-height: 44px;
            text-align: center;
            padding:  40px 0;
        }
    }
}

.Cookie {
    word-wrap: break-word;
}