.customerSupport_chat{
    width: 3rem;
    height: 3rem;
    background: rgba(6, 51, 91, 0.1);
    border-radius: 10px;
    margin-top: 10px;
    padding:0.5rem 0.5rem 0.3rem 0.5rem;
    margin-right: 10px;
    &_icon{
    margin: auto;
    }
}