.Slider{
$size-width: 100%;
$size-height: 207px;



@media screen and(min-width:1000px) {
  $size-height: 336px;
  .carousel{
    img{
      width: 100% !important;
      margin: 18px 0 !important;
      border-radius: 14.5px;
    }
  }
}

.carousel-wrapper {
  width: 100%;
  // height: $size-height;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.carousel-container {
  width: $size-width;
  // height: $size-height;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  // overflow-x: auto;
  // scroll-snap-type: none;
  // scroll-behavior: smooth;
  // -webkit-overflow-scrolling: touch;
  scrollbar-track-color: transparent;
}

.carousel {
  display: flex;
  animation: sliding 8s linear infinite;
  //animation-direction: alternate;
  width: $size-width;
  transition:all .35s ease-in-out;
  // transition-timing-function: ;
  transition-duration: 350ms;
  div {
      flex-shrink: 0;
      width: $size-width;
      border-radius: 10px;
      position: relative;
      justify-content: center;
      align-items: center;
    
  }
  &:hover {
    animation-play-state: paused;
  }
  img{
    height: $size-height;
    width: 94%;
    margin: 18px 3vw;
    border-radius: 10px;
    @media screen and(min-width:1200px) {
      height: 400px;
    }
    @media screen and (max-width:320px) {
          height: 190px;
        }
  }
}
@keyframes sliding {
  30% {
    transform: translateX(0);
  }
  35% {
    transform: translateX(-100%);
  }
  95% {
    transform: translateX(-100%);
   }
   100%{
    transform: translateX(-200%);
   }
  //  100%{
  //   transform: translateX(1%);
  //  }
  // 70% {
  //   transform: translateX(-100% * 2);
  // }
  // 98% {
  //   transform: translateX(-100% * 2);
  // }
  // 100% {
  //   transform: translateX(0);
  // }
}


}