// .Menu-background{
//     position: fixed;
//     top: 2rem;
//     right: 2rem;
//     height: 3rem;
//     width: 3rem;
//     border-radius: 50%;
//     background: #FFF;
//     background-size: cover;
//     background-position: center;
//     z-index: 100;
//     transform: scale(80);
//     transition: all 800ms cubic-bezier(0.86, 0, 0.07, 1);
// }

.Menu {
    position: absolute;
    bottom: -100vh;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 1000;
    background-color:#FFFFFF;
    background-size: cover;
    background-position: center;
   

    a {
        text-decoration: none;

        a {
            font-family: "kap-firma-rg";
        }
    }

    &__Auth {
        overflow-y: scroll;
        width: 75%;
        height: 100%;
        background-color: #FFFFFF;
        padding: 38px 6vw;
        z-index: 1000;
        
        

        &__user {
            display: flex;
            align-items: center;
            gap: 10px;

            &__img {
                width: 63px;
                height: 63px;
                border-radius: 50%;
                background-color: #E0E0E0;
                color: #FFFFFF;
                align-items: center;
                justify-content: center;
                display: flex;
                font-family: 'kap-firma-md';
                font-style: normal;
                font-weight: 600;
                font-size: 30px;
                line-height: 38px;
            }

            &__name {
                font-family: 'kap-firma-md';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;

                color: #1F1F1F;
            }
        }

        &__title {
            font-family: 'kap-firma-rg';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            margin-top: 50px;
        }

        &__profile {
            border-top: 1px solid #000;
            border-bottom: 1px solid #000;
            padding: 22px 0;
            margin: 7px 0;
            display: flex;
            flex-direction: column;
            gap: 24px;

            p>a,
            p {
                font-family: 'kap-firma-rg';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #898989;
                text-transform: capitalize;

            }

            .pop {
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: #06335B;
            }
        }

        &__others {
            padding: 18px 0;
            display: flex;
            flex-direction: column;
            gap: 24px;

            p>a,
            p {
                font-family: 'kap-firma-rg';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #898989;
            }

            div {
                display: flex;
                justify-content: space-between;
                align-items: center;

                p {
                    color: #06335B;
                    font-weight: 600;
                }

                .cs {
                    background-color: #06335B;
                    color: #FFFFFF;
                    font-size: 12px;
                    line-height: 15px;
                    padding: 5px 4px;
                }
            }
        }

        &__accordion {

            p>a,
            p {
                font-family: 'kap-firma-rg';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #898989;
            }

            &__title {
                display: flex;
                align-items: center;
                justify-content: space-between;

            }
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 50px 0;
        gap: 5.35px;
        z-index: 1000;

        img {
            height: 22px;
            width: 86px;
        }

        p {
            font-family: "kap-firma-rg";
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 13px;
            //letter-spacing: 0.1em;
            color: #BDBDBD;
            text-transform: capitalize;
        }

        div {
            display: flex;
            align-items: center;
        }

        .copyright {
            gap: 0.1em;

            img {
                width: 9px;
            }
        }

        .socials {
            gap: 13px;

            img {
                width: 14px;
                height: 15px;
            }
        }
    }

    &__noAuth {
        width: 100%;
        // background-color: #FFFFFF;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 38px 6vw;
        overflow-y: scroll;
        z-index: 1000;
        // border-radius: 50%;
        // transform: scale(80);
        // transition: all 800ms cubic-bezier(0.68, -0.55, 0.265, 1.55);

        a {
            color: #000000 !important;
        }

        p a,
        p,
        li {
            font-family: 'kap-firma-rg';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 25px;
            text-transform: capitalize;
            align-items: center;
        }

        &__accordion {
            padding: 0;
            margin: 0;

            &__title {
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &__content {
                ul {
                    margin: 0;
                    padding: 0;
                }

                .Img-list {
                    margin: 25px 0vw 0 0vw;
                    display: flex;
                    gap: 10px;
                    width: 100%;

                    img {
                        // width: 49px;
                        height: 34px;
                    }
                }

                li {
                    margin: 25px 0vw 0 44px;
                    text-decoration: none;
                    display: block;

                    h3 {
                        font-family: 'kap-firma-rg';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 18px;
                        margin-bottom: 6px;
                    }

                    p {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        color: #656565;
                        text-transform: none;
                    }
                }
            }

        }

        .Menu__footer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 80px 0;
            gap: 8.1px;
            z-index: 1000;

            img {
                height: 26px;
                width: 105px;
            }

            p {
                font-family: "kap-firma-rg";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                // letter-spacing: 0.1em;
                color: #BDBDBD;
                text-transform: capitalize;
            }

            div {
                display: flex;
                align-items: center;
            }

            .copyright {
                gap: 0.1em;

                img {
                    width: 16px;
                }
            }

            .socials {
                gap: 17px;

                img {
                    width: 18px;
                    height: 21px;
                }
            }
        }
    }
    .Desktop {
        display: none;
    }
    @media screen and (min-width:1000px) {
        .Desktop {
            display: block;
        }

        &__noAuth {
            position: fixed;
            width: 100%;
            background-color: #FFFFFF;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 40px;
            padding: 38px 30px;
            overflow-y: scroll;
            z-index: 1000;

            a {
                color: #000000 !important;
            }

            p a,
            p,
            li {
                font-family: 'kap-firma-rg';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 25px;
                text-transform: capitalize;
                align-items: center;
            }

            &__accordion {
                padding: 0;
                margin: 0;

                &__title {
                    padding: 0;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                &__content {
                    display: block;

                    ul {
                        margin: 0;
                        padding: 0;
                        display: flex;
                        flex-wrap: wrap;
                        // gap: 20px;
                        justify-content: space-between;
                    }

                    .Img-list {
                        margin: 25px 0vw 0 0vw;
                        display: flex;
                        gap: 10px;
                        // width: 100%;
                        width: 20vw;

                        img {
                            // width: 49px;
                            height: 32.59px;
                        }
                    }

                    li {
                        margin: 25px 0vw 0 44px;
                        text-decoration: none;
                        display: block;
                        // flex: 20%;
                        width: 20vw;
                        div{
                            width: 300px;
                        }
                        h3 {
                            font-family: 'kap-firma-rg';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 18px;
                            margin-bottom: 6px;
                        }

                        p {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 15px;
                            color: #656565;
                            text-transform: none;
                        }
                    }
                }

            }
        }
    }
}



// @media screen and (min-width:1000px) {
//     .Desktop {
//         display: block;
//     }
// }