.SearchPage{
    .Banner{
        &__image{
            display: none;
        }
        &__buttons{
            padding-top: 24px;
        }
    }
}
 