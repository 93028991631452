@import '../../../config/styles/variables';

.ChatScreen{
    padding-bottom: 120px;
    &__header{
        box-shadow: 0px -10px 20px #707070;
    }
    &__PM{
        padding: 32px 7vw;
        position: relative;
        &__meta{
            display: flex;
            align-items: center;
            padding: 0 16px;
            margin-bottom: 16px;
            .__title{
                font-family: 'kap-firma-rg';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 23px;
                margin-right: 2px;
            }
        }
        &__message{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        &__drop{
            position: absolute;
            bottom: 0;
            left: 47%;
        }
    }
    &__RC{
        padding: 22px 7vw;
        &__meta{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 12px;
            .__title{
                font-family: "kap-firma-rg";
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 23px;
            }
        }
    }
}