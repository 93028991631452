@import "../../config/styles/variables";
.Fpass{
    *{
        font-family: 'kap-firma-md';
        font-style: normal;
        color: #070F1C;
    }
    // .Header{
    //     padding: 5vw 7vw;
    // }
    &_Body{
        margin: 7vh 7vw 10vh 7vw;
        font-family: 'kap-firma-md';
    }
    &_TextHeading{
        margin-top: 130px;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        padding-bottom: 22px;
        text-align: center;
        font-family: 'kap-firma';
    }
    &_text{
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: center;

    }
   
    .success_popup{
        background: #DCFFDB;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        margin-top: 5rem;
        width: 256px;
        height: 77px;
        padding: 21px 16px 21px 11px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
        display: flex;
        &_icon{
            width: 24px;
            height: 24px;
        }
    }
    .success_hide{
        display: none;
    }
    &_Form{
        margin-top: 70px;
        label{
            // font-weight: 500;
            // font-size: 14px;
            // line-height: 18px;
            font-size: 12px;
            color: $primary_colour;
            font-family: "kap-firma-md";
        }
        .text-box, .password{
            width: 100%;
            height: 40px;
            border: 0.2px solid #707070;
            border-radius: 8px;
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            input{
                width: 100%;
                height: 100%;
                border-radius: 8px;
                border: none;
                font-family: "kap-firma-md";
                font-size: 14px;
                padding: 5px;
                flex: 1;
                outline: none ;
            }
        }
        button{
            height: 45px;
            background-color: $btn_colour;
            width: 100%;
            color: $bg_colour;
            border-radius: 12px;
            font-family: "kap-firma";
            font-size: 16px;
            border: none;
            margin: 30px auto;
            position: static;
        }
    }
      
    &_Link{
        text-align: center;
        margin-top: 2.5rem;
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        font-family:'kap-firma';
    }

    a{
        color: #57CC99;
        text-decoration: none;
      }
}